import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useEffect, useState } from 'react'
import { isMobile } from '../../../../formatForBigQuery'
import useRandomNumber from '../../../../hooks/useRandomNumber'
import shuffle from '../../../../lib/shuffle'
import * as links from '../../../internal-links'
import gameDescription from '../../games-description'

export interface RecommendedPracticeGame {
  name: string
  category: string
  description: string
  gameUrl: string
  image: typeof GatsbyImage
}

export const recsPracticeGameNames = {
  PracticePoker: 'Poker Practice',
  PracticeRummy: 'Rummy Practice',
}

export type GameCategories = 'Cards'

const useRecommendPracticeGames = (
  gameName: string,
  category: GameCategories
) => {
  const data = useStaticQuery(graphql`
    {
      Poker: file(
        relativePath: { eq: "Mobile Poster Assets/TEXAS_YOUTUBE Copy.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 984, layout: CONSTRAINED)
        }
      }
      Rummy: file(
        relativePath: { eq: "Mobile Poster Assets/preview copy 2.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 984, layout: CONSTRAINED)
        }
      }
      PokerDesk: file(
        relativePath: {
          eq: "Desktop Recommendation assets/Verticle_TexasHoldem.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 984, layout: CONSTRAINED)
        }
      }
      RummyDesk: file(
        relativePath: { eq: "Desktop Recommendation assets/preview copy 3.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 984, layout: CONSTRAINED)
        }
      }
    }
  `)

  const [isM, setIsM] = useState(false)
  useEffect(() => {
    setIsM(isMobile())
  }, [])

  const cards: RecommendedPracticeGame[] = [
    {
      name: 'Poker Practice',
      category: 'cards',
      description: gameDescription.Poker.short,
      gameUrl: links.POKER_PRACTICE,
      image: data[isM ? 'Poker' : 'PokerDesk'].childImageSharp.gatsbyImageData,
    },
    {
      name: 'Rummy Practice',
      category: 'cards',
      description: gameDescription.Rummy.short,
      gameUrl: links.RUMMY_PRACTICE,
      image: data[isM ? 'Rummy' : 'RummyDesk'].childImageSharp.gatsbyImageData,
    },
  ]

  return {
    recommendations: shuffle([
      cards.filter(g => g.name !== gameName)[
        useRandomNumber(0, cards.length - 1 - (category === 'Cards' ? 1 : 0))
      ],
    ]),
  }
}

export default useRecommendPracticeGames
